import React from "react";
import BtnView from "../Container/main.content.btn.view";
import BtnDelete from "../Container/main.content.btn.delete";
import BtnCheck from "../Container/main.content.btn.check";

export default function TrContent({data, users, photos, userID, leaves}) {
    const colspan = 9;
    let list = [];
    for(const i in data) {
        const requested_by = data[i].requested_by;
        const user = (requested_by in users) ? users[requested_by] : "";
        data[i].full_name = "";
        data[i].photo = "";
        data[i].leave_days_balance = 0;
        data[i].leave_hours_balance = 0;
        if(user != "") {
            data[i].full_name = user["full_name"];
            data[i].photo = (user["photo"] in photos) ? photos[user["photo"]]["image_url"] : "";
        }
        if(requested_by in leaves && leaves[requested_by].leave_days_balance > 0) {
            let leave_days_balance = leaves[requested_by].leave_days_balance
            let sp = (leave_days_balance.toString()).split(".")
            data[i].leave_days_balance = parseInt(sp[0])
            if(sp.length > 1) {
                data[i].leave_hours_balance = (parseInt(sp[1])/Math.pow(10, sp[1].length))*8
            }
        }
        list.push(<tr key={"row-" + i}>
            <Feature.TdContent 
                data={data[i]} 
                index={i} 
                userID={userID} />
        </tr>);
    }
    if(data.length == 0) {
        list.push(<tr key="row-1">
            <td colSpan={colspan}>No Data</td>
        </tr>);
    }
    return list
}

const Feature = {};
Feature.TdContent = ({data, index, userID}) => {
    let td = [];
    let btnAction = "";
    if(data.approver_id == userID && (data.status_code == "PD" || data.status_code == "RQ")) {
        btnAction = <div className="btn-action">
            <BtnCheck id={data.id} />
            <BtnDelete id={data.id} />
        </div>
    }
    let userPhoto = "";
    if(data.photo != "") {
        userPhoto = <div className="image-circle" style={{
            background: "url('" + data.photo + "') no-repeat center", 
            backgroundSize: "cover"}} />
    }
    else {
        userPhoto = <div className="image-circle">
            <i className="material-icons">person</i>
        </div>
    }
    let leaveBalanceDay = data.leave_days_balance + " day" + (data.leave_days_balance > 1 ? "s" :"");
    let leaveBalanceHour = "";
    if(data.leave_hours_balance > 0) {
        leaveBalanceHour = "(+" + data.leave_hours_balance + " hour" + (data.leave_hours_balance > 1 ? "s" : "") + ")";
    }

    td.push(<td key={"row-" + index + "-col-photo"} className="td-photo">
        {userPhoto}
    </td>);
    td.push(<td key={"row-" + index + "-col-name"} className="text-left">
        <span className="text">{data.full_name}</span>
    </td>);
    td.push(<td key={"row-" + index + "-col-startdate"}>
        <span className="text">{data.full_start_date}</span>
        {(data.full_start_time != "") ? <span className="small-text">{data.full_start_time}</span> : ""}
    </td>);
    td.push(<td key={"row-" + index + "-col-enddate"}>
        <span className="text">{data.full_end_date}</span>
        {(data.full_end_time != "") ? <span className="small-text">{data.full_end_time}</span> : ""}
    </td>);
    td.push(<td key={"row-" + index + "-col-total"}>
        <span className="text total">{data.full_total_days}</span>
    </td>);
    td.push(<td key={"row-" + index + "-col-leavetype"}>
        <span className="text">{data.leavetype_name}</span>
    </td>);
    td.push(<td key={"row-" + index + "-col-status"}>
        <div className={"status-text " + data.status_code}>
            {data.status_desc}
        </div>
    </td>);
    td.push(<td key={"row-" + index + "-col-balance"} style={{color: "#728431"}}>
        <span className="text-day" style={{fontWeight: "bold"}}>{leaveBalanceDay}</span>
        {(leaveBalanceHour != "") ? <React.Fragment>
            <br /><span className="text-hour" style={{fontSize: "13px"}}>{leaveBalanceHour}</span>
        </React.Fragment> : null}
    </td>);
    td.push(<td key={"row-" + index + "-col-view"}>
        <BtnView id={data.id} />
    </td>);
    td.push(<td key={"row-" + index + "-col-change"}>
        {btnAction}
    </td>);
    return td;
}