// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const COOKIE_NAME = "google_login_token";
  const [googleProfile, setGoogleProfile] = useState({
    email: "",
    err: 0,
    err_msg: "",
  });
  const maxRetries = 5;
  const retryInterval = 2000;

  // Utility to manage cookies
  const setLoginCookie = (email) => {
    const tokenData = { email, timestamp: Date.now() };
    const expires = new Date(new Date().getTime() + 3 * 60 * 60 * 1000); // 3 hours
    Cookies.set(COOKIE_NAME, JSON.stringify(tokenData), { expires });
  };

  const getLoginCookie = () => {
    try {
      const cookie = Cookies.get(COOKIE_NAME);
      return cookie ? JSON.parse(cookie) : null;
    } catch (error) {
      console.error("Failed to parse login cookie:", error);
      return null;
    }
  };

  // Helper to check popups
  const isPopupBlocked = () => {
    const popup = window.open("", "_blank", "width=200,height=200");
    if (popup) {
      popup.close();
      return false;
    }
    return true;
  };

  // Retry mechanism for loading gapi
  const loadGapiWithRetry = async () => {
    for (let attempt = 1; attempt <= maxRetries; attempt++) {
      if (window.gapi) {
        console.log("Google API script loaded successfully");
        return new Promise((resolve, reject) => {
          window.gapi.load("auth2", {
            callback: resolve,
            onerror: () =>
              reject(new Error("Failed to load Google Auth library")),
            timeout: 5000,
            ontimeout: () =>
              reject(new Error("Google Auth library loading timed out")),
          });
        });
      }
      console.warn(
        `Google API script not loaded. Retrying (${attempt}/${maxRetries})...`
      );
      await new Promise((res) => setTimeout(res, retryInterval));
    }
    throw new Error("Google API script not loaded after multiple retries");
  };

  // Initialize Google Auth
  const initializeAuth2 = async () => {
    try {
      await loadGapiWithRetry();
      await window.gapi.auth2.init({
        client_id: process.env.REACT_APP_CLIENTID,
        ux_mode: "popup",
        scope: "email profile",
      });
      console.log("Google Auth initialized");
    } catch (error) {
      console.error("Error initializing Google Auth:", error);
      setGoogleProfile({ email: "", err: 1, err_msg: error.message });
      throw error;
    }
  };

  // Handle Google login success
  const handleLoginSuccess = (googleUser) => {
    const profile = googleUser.getBasicProfile();
    const userData = {
      fullName: profile.getName(),
      email: profile.getEmail(),
    };

    /*
    Use test email for check login
    */
    if (
      process.env.REACT_APP_TEST_LOGIN_USER_EMAIL !== "" &&
      process.env.REACT_APP_TEST_LOGIN_USER_EMAIL !== undefined &&
      userData.email === process.env.REACT_APP_ADMIN_USER_EMAIL
    ) {
      userData.email = process.env.REACT_APP_TEST_LOGIN_USER_EMAIL;
    }

    setLoginCookie(userData.email);
    setGoogleProfile({ email: userData.email, err: 0, err_msg: "" });
    console.log("Login successful:", userData);
  };

  // Login using Google Auth
  const login = async () => {
    if (isPopupBlocked()) {
      const err_msg =
        "Popups are blocked. Please disable popup blocking for this site.";
      setGoogleProfile({ email: "", err: 1, err_msg });
      return;
    }

    try {
      const auth2 = window.gapi.auth2.getAuthInstance();
      const googleUser = await auth2.signIn();
      handleLoginSuccess(googleUser);
    } catch (error) {
      console.error("Error signing in:", error);
      setGoogleProfile({
        email: "",
        err: 1,
        err_msg: error.message || "Sign-in failed",
      });
    }
  };

  // Check login status
  const checkLoginStatus = async () => {
    const cookieData = getLoginCookie();

    if (cookieData) {
      /*
      Use test email for check login
      */
      if (
        process.env.REACT_APP_TEST_LOGIN_USER_EMAIL !== "" &&
        process.env.REACT_APP_TEST_LOGIN_USER_EMAIL !== undefined &&
        cookieData.email === process.env.REACT_APP_ADMIN_USER_EMAIL
      ) {
        cookieData.email = process.env.REACT_APP_TEST_LOGIN_USER_EMAIL;
      }

      setGoogleProfile({ email: cookieData.email, err: 0, err_msg: "" });
      console.log("User is already logged in from cookie");
      return;
    }

    try {
      await initializeAuth2();
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2?.isSignedIn.get()) {
        const googleUser = auth2.currentUser.get();
        handleLoginSuccess(googleUser);
      } else {
        login();
      }
    } catch (error) {
      console.error("Error during login status check:", error);
      setGoogleProfile({ email: "", err: 1, err_msg: error.message });
    }
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  return (
    <AuthContext.Provider
      value={{ googleProfile, setGoogleProfile, checkLoginStatus, login }}
    >
      {children}
    </AuthContext.Provider>
  );
};
