import React from 'react';
import config from "../../config";

function Calendar() {

  var gapi = window.gapi
  /* 
    Update with your own Client Id and Api key 
  */
  var CLIENT_ID = config.clientId;
  var API_KEY = config.googleApiKey;
  var DISCOVERY_DOCS = [config.googleDiscoveryDocs];
  var SCOPES = config.googleScopes;

  const handleClientLoad = () => {
    gapi.load('client:auth2', initClient);
  }

  const initClient = () => {
    gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })

      //gapi.client.load('calendar', 'v3', () => console.log('bam!'))
      //gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
      gapi.auth2.getAuthInstance().signIn()
      .then(() => {
          console.log("hello");
          var event = {
            'summary': 'Test Event!',
            'description': 'Really great refreshments',
            'start': {
                'date': "2021-08-29"
            },
            'end': {
                'date': "2021-08-29"
            },
          }
          var request = gapi.client.calendar.events.insert({
            'calendarId': 'primary',
            'resource': event,
          })
  
          request.execute(event => {
            console.log(event)
            //window.open(event.htmlLink)
          })
      }, 
      function(error) {
            console.log(error);
      });

    /*gapi.client.init({
      apiKey: API_KEY,
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES
    }).then(function () {
      // Listen for sign-in state changes.
      console.log("xxx");
      gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

      // Handle the initial sign-in state.
      updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
      //authorizeButton.onclick = handleAuthClick;
      //signoutButton.onclick = handleSignoutClick;
    }, function(error) {
      console.log(JSON.stringify(error, null, 2));
    });*/
  }

  function updateSigninStatus(isSignedIn) {
    if (isSignedIn) {
      console.log("Login");
      //listUpcomingEvents();
    } else {
      console.log("No Login");
    }
  }

    const handleClick = () => {
    gapi.load('client:auth2', () => {
      console.log('loaded client')

      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })

      gapi.client.load('calendar', 'v3', () => console.log('bam!'))

      gapi.auth2.getAuthInstance().signIn()
      .then(() => {
        
        var event = {
          'summary': 'Awesome Event!',
          'location': '800 Howard St., San Francisco, CA 94103',
          'description': 'Really great refreshments',
          'start': {
            'dateTime': '2020-06-28T09:00:00-07:00',
            'timeZone': 'America/Los_Angeles'
          },
          'end': {
            'dateTime': '2020-06-28T17:00:00-07:00',
            'timeZone': 'America/Los_Angeles'
          },
          'recurrence': [
            'RRULE:FREQ=DAILY;COUNT=2'
          ],
          'attendees': [
            {'email': 'lpage@example.com'},
            {'email': 'sbrin@example.com'}
          ],
          'reminders': {
            'useDefault': false,
            'overrides': [
              {'method': 'email', 'minutes': 24 * 60},
              {'method': 'popup', 'minutes': 10}
            ]
          }
        }

        var request = gapi.client.calendar.events.insert({
          'calendarId': 'primary',
          'resource': event,
        })

        request.execute(event => {
          console.log(event)
          //window.open(event.htmlLink)
        })
        

        /*
            Uncomment the following block to get events
        */
        /*
        // get events
        gapi.client.calendar.events.list({
          'calendarId': 'primary',
          'timeMin': (new Date()).toISOString(),
          'showDeleted': false,
          'singleEvents': true,
          'maxResults': 10,
          'orderBy': 'startTime'
        }).then(response => {
          const events = response.result.items
          console.log('EVENTS: ', events)
        })
        */
    

      })
    })
    }


  return (
    <div className="App">
      <header className="App-header">
        <p>Click to add event to Google Calendar</p>
        <p style={{fontSize: 18}}>Uncomment the get events code to get events</p>
        <p style={{fontSize: 18}}>Don't forget to add your Client Id and Api key</p>
        <button style={{width: 100, height: 50}} onClick={handleClientLoad}>Add Event</button>
      </header>
    </div>
  );
}

export default Calendar;