import React from 'react';
import { useParams } from "react-router";
import { Buffer } from "buffer";
//import "./style.css";
import config from "../../config";
import Html from "../../utils/Html";
import ModalEdit from "./modal.edit";

const TeamActionReject = (props) => {
    let { reqId } = useParams();
    let userData = window.$user_profile;
    if (!('user_sess' in userData) || !userData["user_sess"].userCanAccessTeamView){
        return <Html.NoAccess />;
    }
    if(reqId != "") {
        let base64ToString = Buffer.from(reqId, "base64").toString();
        reqId = base64ToString;
    }
    if(isNaN(Number(reqId))) {
        window.location.href = config.site.teamWaiting;
    }
    return (<div id="main-content">
        <ModalEdit reqId={reqId} action={"reject"} />
    </div>);
}
export default TeamActionReject;
