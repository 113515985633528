import React from "react";
import { TextInput, Checkbox } from 'react-materialize';
import CheckboxAction from "./main.content.checkbox.action";
import InputAnnual from "./main.content.input.annual";

export default function TrContent({data, actionData, userData, setActionData}) {
    const userSess = userData.user_sess;
    const colspan = 12;
    let list = [];
    for(const i in data) {
        list.push(<tr key={"row-" + i}>
            <Feature.TdContent 
                data={data[i]} 
                index={i} 
                actionData={actionData} 
                userSess={userSess}
                setActionData={setActionData} />
        </tr>);
    }
    if(data.length == 0) {
        list.push(<tr key="row-1">
            <td colSpan={colspan}>No Data</td>
        </tr>);
    }
    return list
}
 
const Feature = {};
Feature.TdContent = ({data, index, actionData, userSess, setActionData}) => {
    let td = [];
    let userPhoto = "";
    if(data.photo != "") {
        userPhoto = <div className="image-circle" style={{
            background: "url('" + data.photo + "') no-repeat center", 
            backgroundSize: "cover"}} />
    }
    else {
        userPhoto = <div className="image-circle">
            <i className="material-icons">person</i>
        </div>
    }
    td.push(<td key={"row-" + index + "-col-photo"} className="td-photo">
        {userPhoto}
    </td>);
    td.push(<td key={"row-" + index + "-col-name"} className="text-left">
        <span className="text">{data.full_name}</span>
    </td>);
    td.push(<td key={"row-" + index + "-col-country"} className="text-left">
        <span className="text">{data.country_name}</span>
    </td>);
    td.push(<td key={"row-" + index + "-col-days_entitled"} className="text-center">
        <InputAnnual data={data} type="days_entitled" userSess={userSess} />
    </td>);
    td.push(<td key={"row-" + index + "-col-annual_leave_days"} className="text-center">
        <InputAnnual data={data} type="annual_leave_days" userSess={userSess} />
    </td>);
    td.push(<td key={"row-" + index + "-col-last_year_balance"} className="text-center">
        <InputAnnual data={data} type="last_year" userSess={userSess} />
    </td>);
    td.push(<td key={"row-" + index + "-col-current_year_balance"} className="text-center">
        <TextInput
            className="inp-assign-disable no-default"
            id="inp-req-current_year_balance" 
            name="current_year_balance"
            type="text"
            defaultValue={(data.current_year_balance).toString()}
            disabled
        />
    </td>);
    td.push(<td key={"row-" + index + "-col-leave_days_taken"} className="text-center">
        <TextInput
            className="inp-assign-disable no-default"
            id="inp-req-leave_days_taken" 
            name="leave_days_taken"
            type="text"
            defaultValue={(data.leave_days_taken).toString()}
            disabled
        />
    </td>);
    td.push(<td key={"row-" + index + "-col-leave_days_expired"} className="text-center">
        <TextInput
            className={"inp-assign-disable" + (data.is_expired && data.leave_days_expired > 0 ? "-expired" : "") + " no-default"}
            id="inp-req-leave_days_expired" 
            name="leave_days_expired"
            type="text"
            defaultValue={(data.leave_days_expired).toString()}
            disabled
        />
    </td>);
    td.push(<td key={"row-" + index + "-col-leave_days_balance"} className="text-center">
        <TextInput
            className={"inp-assign-disable no-default"}
            id="inp-req-leave_days_balance" 
            name="leave_days_balance"
            type="text"
            defaultValue={(data.leave_days_balance).toString()}
            disabled
        />
    </td>);
    td.push(<td key={"row-" + index + "-col-sick_leave_used"} className="text-center">
        <TextInput
            className="inp-assign-disable no-default"
            id="inp-req-sick_leave_used" 
            name="sick_leave_used"
            type="text"
            defaultValue={(data.sick_leave_used).toString()}
            disabled
        />
    </td>);
    /*td.push(<td key={"row-" + index + "-col-compensation_leave_days"} className="text-center">
        <TextInput
            className="inp-assign no-default"
            id="inp-req-compensation_leave_days" 
            name="compensation_leave_days"
            type="text"
            //defaultValue={(data?.compensatory_leave_days).toString()}
        />
    </td>);*/
    td.push(<td key={"row-" + index + "-col-compensatory_leave_used"} className="text-center">
        <TextInput
            className="inp-assign-disable no-default"
            id="inp-req-compensatory_leave_used" 
            name="compensatory_leave_used"
            type="text"
            defaultValue={(data.compensatory_leave_used).toString()}
            disabled
        />
    </td>);
    td.push(<td key={"row-" + index + "-col-action"} className="text-center">
        <CheckboxAction 
            data={data} 
            index={index} 
            actionData={actionData}
            setActionData={setActionData} />
    </td>);
    
    return td;
}